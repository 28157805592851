//import createjs from 'preload-js'
import store from '@/store'
import gsap, { Power3 } from 'gsap/all'
import router from '@/router'
const gh = {
    globalSectionInit: function() {
        /*
        setTimeout(function() {
            //gh.aosInit()
        },500)

         */
    },
    scrollTo(route, window) {
        const tid = '#section-' + route
        gsap.to(window, {
            duration: 0.5,
            scrollTo:tid,
            easing: Power3.easeInOut
        })
    },
    enableBodyScroll(enable) {
        if (enable) {
            document.body.classList.remove("no-scroll")
            window.scrollTo(0, gh.bodyScrollY)
            document.body.style.top = 0
        } else {
            gh.bodyScrollY = window.scrollY
            document.body.classList.add("no-scroll")
            document.body.style.top = -gh.bodyScrollY+'px'
        }
    },
    stripSlashesFrontAndBack(val) {
        if (val) {
            val = val.toString()
            if(val.substr(-1) === '/') {
                val = val.substr(0, val.length - 1);
            }
            if(val.substr(0,1) === '/') {
                val = val.substr(1, val.length);
            }
        }
        return val
    },

    getRouteObjectFromVal(val, grabFromPath = false) {
        let ret = null
        let comparator = (grabFromPath) ? 'path' : 'hook'
        for (let i = 0;i < window.config.json.global.routes.length;i++) {
            let obj = window.config.json.global.routes[i]
            if (obj[comparator] === val) {
                ret = obj
            }
        }
        return ret
    },
    pingAnalyticsCustom(section, val, eventLabel=null,eventValue=null ) {
        let obj = {
            'event_category' : val,
            'event_label': eventLabel,
            'value': eventValue
        }
        //console.log('gtag', obj)
        window.gtag('event', section, obj)
    },
    pingAnalyticsChangeRoute(val) {
        if (val) {
            try {
                let eventLabel = null
                let eventValue = null
                if (store.state.currentSubsection?.includes('library')) {
                    eventLabel = String(store.state.currentLibrarySubsection)
                    eventValue = String(store.state.currentLibraryNamesContent)
                }
                gh.pingAnalyticsCustom('section', val, eventLabel, eventValue)
                let obj = gh.getRouteObjectFromVal(val)
                if (obj) {
                    router.push(obj)
                }

            } catch (e) {
                console.log('gtag', e, val)
            }
        }
    },
    openOverlay(type) {
        store.commit('setOverlay',{
            type: type
        })
    },
    closeOverlay() {
        store.commit('setOverlay',null)
    }
}
export default gh
