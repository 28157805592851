<template>
    <div class="youtube-player">
        <div class="youtube-container">
            <YouTube
                :src="youTubeFullSource"
                :vars="youtubeParameters"
                className="ytplayerComponent"
                width="100%"
                height="100%"
                @ready="onReady"
                @state-change="onStateChange"
                ref="youtube"
            />
            <div v-show="!isPlaying" class="bgimg cover-image" :style="{'background-image': 'url(' + this.coverImage + ')'}"></div>
            <div v-show="!isPlaying" class="youtube-start abs100">
                <div class="item-play" v-show="!isLoading" @click="playVideo">
                    <span class="material-icons"></span>
                </div>
                <div class="item-loading" v-show="isLoading">

                </div>
            </div>
            <div v-show="false && isPlaying" class="youtube-controls" >
                <div class="btn-pause" @click="pauseVideo">
                    <span class="material-icons">pause</span>
                </div>
                <div class="btn-sound" @click="muteToggle" >
                    <span v-if="isMuted" class="material-icons unmuted">volume_up</span>
                    <span v-if="!isMuted" class="material-icons muted">volume_off</span>
                </div>
                <div :id="scrubberId" class="scrubber" @click="seekTo">
                    <div class="bg-line"></div>
                    <div :style="fillLineStyle" class="fill-line"></div>
                    <div class="dragger"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// https://www.npmjs.com/package/vue3-youtube
// https://developers.google.com/youtube/player_parameters
import YouTube from 'vue3-youtube'
import { mapState } from 'vuex'
export default {
    name: "YouTubeCustom",
    components: {
        YouTube
    },
    props: {
        youTubeId: {
            type: String,
            required: true,
        },
        coverImage: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            document:document,
            scrubberId: 'youtube-player-scrubber-'+Date.now(),
            youtubeParameters:{
                rel: 0,
                controls: 1,
                modestbranding: 1,
                autohide: 1,
                playsinline: 1,
                enablejsapi: 1,
            },

            isLoading:false,
            isPlaying:false,
            isMuted:false,
            scrubberTimer: null,
            videoDuration: 0,
            videoCurrentTime: 0,

        }
    },
    computed: {
        ...mapState({
            youTubeIsClickable: state => state.youTubeIsClickable,
        }),
        youTubeFullSource() {
            return `https://www.youtube.com/watch?v=${this.youTubeId}`
        },
        fillLineStyle() {
            let pct = 0
            if (this.videoDuration>0) {
                pct = (this.videoCurrentTime / this.videoDuration) * 100
                //pct = this.videoCurrentTime / this.videoDuration
            }
            return `width:${pct}%`
        }
    },
    mounted() {

    },
    watch: {
        isPlaying() {
            if (this.isPlaying) {
                //this.scrubberTimer = setInterval(this.scrubberTimerCallback, 250)
            } else{
                //clearInterval(this.scrubberTimer)
            }
        }
    },
    methods: {
        onReady() {
            this.videoDuration = this.$refs.youtube.getDuration()
        },
        onStateChange() {
            let playerState = this.$refs.youtube.getPlayerState()
            switch (playerState) {
                case -1: //unstarted
                    this.setEndedState()
                    break
                case 0: //ended
                    this.stopVideoComponentCall()
                    break
                case 1: //playing
                    this.setPlayingState()
                        break
                case 2: //paused
                    //this.setEndedState()  //UNDO IF CUSTOM CONTROl
                    break
                case 3: //buffering
                    //this.setBufferingState() //UNDO IF CUSTOM CONTROl
                    break
                case 5: //video cued
                    break
            }
        },
        playVideo() {
            //if (this.youTubeIsClickable) {
                if (this.$refs.youtube) {
                    try {
                        this.$refs.youtube.playVideo()
                    } catch (error) {
                        console.log('e', error)
                    }
                }
            //}
        },
        pauseVideo() {
            this.setEndedState() //trigger early to prevent flashes
            this.$refs.youtube.pauseVideo()
        },
        stopVideo() {
          this.pauseVideo()
          this.seekTo(0)
        },
        muteToggle() {
            if (this.$refs.youtube.isMuted()) {
                this.$refs.youtube.unMute()
                this.isMuted = false
            } else {
                this.$refs.youtube.mute()
                this.isMuted = true
            }
        },
        scrubberTimerCallback() {
            this.videoCurrentTime = this.$refs.youtube.getCurrentTime()
        },
        seekTo(e) {
            const elem = this.document.getElementById(this.scrubberId)
            const left = e.offsetX
            const width = elem.offsetWidth
            const pct = left/width
            const seconds = pct * this.videoDuration
            this.$refs.youtube.seekTo(seconds, true)
        },

        setPlayingState() {
            //trigger late to prevent flashes
            let t = this
            setTimeout(function() {
                t.isPlaying = true
                t.isLoading = false
            }.bind(t),150)

        },
        setEndedState() {
            this.isPlaying = false
            this.isLoading = false
        },
        setBufferingState() {
            this.isLoading = true
        },
        stopVideoComponentCall() {
            this.setEndedState()
            if (this.$refs.youtube) {
                this.$refs.youtube.stopVideo()
            }
        },
        startVideoComponentCall() {
            this.playVideo()
        }
    },

}
</script>