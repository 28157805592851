import { createApp } from 'vue'
import App from './App.vue'

//globals
import InlineSvg from 'vue-inline-svg'
//import VueGtag from "vue-gtag-next"
import router from './router'
import { store } from './store'


startApp()

function startApp () {
    //LOAD
    const app = createApp(App)
    app.use(router)
    app.use(store)

    //global inline svg
    app.component('inline-svg', InlineSvg)

    app.mount('#app')
}