//REACTIVE
import { createStore } from 'vuex'
export const store = createStore({
    state () {
        return {
            overlayObject:null,
            routerNext: null,
        }
    },
    mutations: {
        setOverlay(state, obj=null) {
            //console.log('ssss', state, obj)
            state.overlayObject = obj
        },
    }
})
export default store
